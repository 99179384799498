<script>
import TeamMember from './TeamMember.vue';

export default {
	props: ["department"],
	computed: {
		getLanguage() {
			return this.$store.state.language;
		},
		getContent() {
			return this.$store.state;
		},
		currentDepartment() {
			// previously used .toReversed() instead of .sort(compareMembersByJoined)
			return this.getContent.team.all_members_cont[this.getLanguage].filter((person) => {
				return person.department === this.department.id;
			});
		},
	},
	methods: {
		compareMembersByJoined(a, b) {
			let aDate = this.joinDateToNumeric(a.semesterJoined)
			let bDate = this.joinDateToNumeric(b.semesterJoined)
			
			if (aDate < bDate) {
				return -1;
			}
			if (aDate > bDate) {
				return 1;
			}
			return 0;
		},
		joinDateToNumeric(inputDate) {
			// Extract the digits and the letter from the input string
			const digits = parseInt(inputDate.slice(0, 2));
			const letter = inputDate.slice(2);
			
			// Convert the digits followed by the letter into a numerical type
			let numericResult = digits;
			
			if (letter === 'W') {
				numericResult = digits * 10 + 1;
			} else if (letter === 'S') {
				numericResult = digits * 10;
			}
			
			return numericResult;
		},
	},
	
	components: { TeamMember }
}


</script>

<template>
	<div class="container text-center mt-5 mb-3">
		<h1 class="mb-3 department-title">{{ department.name }}</h1>
		<p class="department-description">{{ department.description }}</p>
		<div class="container mt-3 mb-3">
			<div class="row d-flex justify-content-center">
				<TeamMember v-for="member in currentDepartment" v-if="member.position === 'Head'"
					v-bind:key="member.name" :teamMember="member" />
				<TeamMember v-for="member in currentDepartment.sort(compareMembersByJoined)"
					v-if="member.position !== 'Head' && member.position !== 'Vice Head'" v-bind:key="member.name"
					:teamMember="member" />
			</div>
		</div>
	</div>
</template>


<style>
.department-description {
	font-family: Open Sans, sans-serif;
	color: #888087;
	font-size: 18px;
	margin-bottom: 4rem;
	margin-left: 40px;
	margin-right: 40px;
}

.department-title {
	font-family: Avenir Next, sans-serif;
	font-weight: normal;
	font-size: 2rem;
}


@media screen and (max-width: 550px) {
	.department-description {
		margin-left: 20px;
		margin-right: 20px;
		font-size: 14px;
	}
}
</style>