<script>

export default {
	props: ["teamMember"]


}
</script>

<template>
	<div class="col-md-3 person-card">
		<div class="bg-white p-3 text-center rounded box shadow-member">
			<img class="img-responsive rounded-circle team-member-img" :src="teamMember.image" width="120" height="120"
				alt="profile photo">
			<h5 class="mt-3 name"><span v-html="teamMember.name"></span></h5>
			<section v-if="typeof (teamMember.position) != 'undefined' && teamMember.position != null">
				<span class="work d-block member-position" v-html="teamMember.position"></span>
			</section>
			<section v-else>
				<span class="work d-block member-position" v-html="'&nbsp;'"></span>
			</section>
			<div class="mt-2 about degree-container">
				<span class="work member-degree" v-for="i in teamMember.degrees.length">
					{{ teamMember.degrees[i - 1] }} {{ teamMember.degreeType[i - 1] }} | {{ teamMember.university[i - 1]
					}}<br>
				</span>
			</div>

			<section id="linkedin-section"
				v-if="typeof (teamMember.linkedin) != 'undefined' && teamMember.linkedin != null">
				<div class="icon-container">
					<a :href="teamMember.linkedin">
						<svg class="linkedin-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26"
							viewBox="0 0 32 32">
							<path id="Icon_awesome-linkedin" data-name="Icon awesome-linkedin"
								d="M29.714,2.25H2.279A2.3,2.3,0,0,0,0,4.557V31.943A2.3,2.3,0,0,0,2.279,34.25H29.714A2.3,2.3,0,0,0,32,31.943V4.557A2.3,2.3,0,0,0,29.714,2.25ZM9.671,29.679H4.929V14.407h4.75V29.679ZM7.3,12.321a2.75,2.75,0,1,1,2.75-2.75,2.751,2.751,0,0,1-2.75,2.75ZM27.45,29.679H22.707V22.25c0-1.771-.036-4.05-2.464-4.05-2.471,0-2.85,1.929-2.85,3.921v7.557H12.65V14.407H17.2v2.086h.064a5,5,0,0,1,4.493-2.464c4.8,0,5.693,3.164,5.693,7.279Z"
								transform="translate(0 -2.25)" />
						</svg>
					</a>
				</div>
			</section>
		</div>
	</div>
</template>

<style lang="scss">
.person-card {
	// height if skills would be included
	// height: 520px;
	height: 400px;
}

.member-position {
	color: #8c8c8c;
	margin-top: 2px;
}

.name {
	margin-bottom: 0;
}

.team-member-img {
	border: 1px solid #e6e6e6;
}

.member-skill {
	padding: 4px 10px 4px 10px;
	background-color: rgb(241, 245, 248);
	color: #7a858e;
	border-radius: 10px;
	display: inline-block;
	margin-bottom: 6px;
	margin-left: 2px;
	margin-right: 2px;
	transition: all 0.2s ease-in-out;

	&:hover {
		background-color: rgb(228, 233, 236);
	}
}

@media (min-width: 768px) {
	.person-card {
		-ms-flex: 0 0 33%;
		-webkit-box-flex: 0;
		flex: 0 0 33%;
		max-width: 33%;
		padding: 15px;
	}
}

.shadow-member {
	-webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.05);
	box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.05);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.2s ease-in-out;

	&:hover {
		-webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
		box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
	}
}

.linkedin-icon {
	transition: all 0.3s ease-in-out;
	fill: #344f6a83;
	//margin-top: 20px;

	&:hover {
		fill: #0077B5;
	}
}

#linkedin-section {
	position: relative;
	height: 100%;
	margin-right: 1vw;
}

.icon-container {
	position: absolute;
	bottom: 0;
	//margin-top: auto;
	//margin-bottom: 1.5rem
}

@media screen and (max-width: 1000px) {
	.person-card {
		-ms-flex: 0 0 50%;
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		max-width: 50%;
		padding: 15px;
		margin-bottom: 15px;
	}

	.person-card {
		height: 480px;
	}
}

@media screen and (max-width: 1000px) {
	.person-card {
		height: 350px;
		padding: 4px;
	}

	.member-skill {
		display: none;
	}
}

@media screen and (max-width: 900px) {
	.person-card {
		height: 350px;
		padding: 4px;
	}

	.member-skill {
		display: none;
	}

	.degree-container {
		margin-top: 5px !important;
	}
}

@media screen and (max-width: 800px) {
	.person-card {
		-ms-flex: 0 0 100%;
		-webkit-box-flex: 0;
		flex: 0 0 100%;
		max-width: 100%;
		padding: 15px;
		height: auto;
	}

	#linkedin-section {
		height: 10vh;
	}
}
</style>